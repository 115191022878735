
export default {
    namespaced: true,
    state() {
        return {
            tab: 0,
        };
    },
    mutations: {
        changeTab(state, tab) {
            state.tab = tab;
        },
    },
    actions: {
    },
};
