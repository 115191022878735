import { createStore } from "vuex";
import tab from "./tab"
import lang from "./lang"

const store = createStore({
  modules: {
    tab,
    lang
  },
});

export default store;
