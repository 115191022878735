<template>
    <div class="sub__page--tabview">
        <div class="sub__page--tabs">
            <button v-for="tab in tabs" :key="tab.name" :class="{ active: currentTab === tab.name }"
                @click="selectTab(tab.name)">
                {{ tab.label }}
                <p class="tab__button--icon"></p>
            </button>
        </div>
        <div class="tab__content">
            <slot :name="currentTab"></slot>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "TabView",
    props: {
        tabs: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            currentTab: this.tabs[0].name,
        };
    },
    computed: {
        ...mapState('tab', ["tab"]),
    },
    mounted() {
        this.loadTab();
    },
    watch: {
        tabs(newTabs) {
            if (!newTabs.some(tab => tab.name === this.currentTab)) {
                this.currentTab = newTabs[0].name;
            }
        }
    },
    methods: {
        loadTab() {
            if (this.tab) {
                this.currentTab = this.tab;
            }
            else {
                this.currentTab = 'tab1';
            }
        },
        selectTab(tabName) {
            this.currentTab = tabName;
            this.$emit('update:currentTab', tabName);
        }
    }
};
</script>
