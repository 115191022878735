<template>
    <div>
        <div class="sub__page--subtabs">
            <button v-for="subtab in subtabs" :key="subtab.name" :class="{ active: currentSubTab === subtab.name }"
                @click="currentSubTab = subtab.name">
                {{ subtab.label }}
                <p class="subtab__button--icon"></p>
            </button>
        </div>
        <div class="subtab__content">
            <slot :name="currentSubTab"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "SubTabView",
    props: {
        subtabs: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            currentSubTab: this.subtabs[0].name,
        };
    },
    watch: {
        subtabs(newSubtabs) {
            if (!newSubtabs.some(subtab => subtab.name === this.currentSubTab)) {
                this.currentSubTab = newSubtabs[0].name;
            }
        }
    }
};
</script>
