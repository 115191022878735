<template>
    <div class="pagination" v-if="totalPages > 1">
        <button v-if="currentPage > 1" @click="prevPage" style="color: var(--button-color);">«</button>
        <button v-for="page in visiblePages" :key="page" :class="{ active: page === currentPage }"
            @click="changePage(page)">
            {{ page }}
        </button>
        <button v-if="currentPage < totalPages" @click="nextPage" style="color: var(--button-color);">»</button>
        <button v-if="currentPage < totalPages" @click="goToLastPage" style="color: var(--button-color);">»»</button>
    </div>
</template>

<script>
export default {
    props: {
        totalPages: {
            type: Number,
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
        },
    },
    computed: {
        visiblePages() {
            const pages = [];
            for (let i = 1; i <= this.totalPages; i++) {
                pages.push(i);
            }
            return pages;
        },
    },
    methods: {
        prevPage() {
            if (this.currentPage > 1) {
                this.$emit('update:currentPage', this.currentPage - 1);
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.$emit('update:currentPage', this.currentPage + 1);
            }
        },
        changePage(page) {
            if (page !== this.currentPage) {
                this.$emit('update:currentPage', page);
            }
        },
        goToLastPage() {
            this.$emit('update:currentPage', this.totalPages);
        },
    },
};
</script>

<style></style>
