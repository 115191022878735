<template>
  <div id="main__section" class="section4">
    <div id="screen__pc" class="section4__textbox">
      <div class="section4__maintext">
        <h1>Our<br />Business</h1>
        <h1 class="section__textline"></h1>
      </div>
      <div class="section4__subtext">
        <p>{{ $t('mainSec4.text--1') }}</p>
      </div>
    </div>
    <div id="screen__pc" class="section4__content" >
      <div v-for="(cardTitle, i) in cardTitles" @click="goToBusiness(i)" :class="`section4__content--card card--${i}`" :key="i" >
        <div class="card__detail">
          <h3>{{ cardTitle }}</h3>
          <p v-html="cardDetails[i]"></p>
        </div>
      </div>
    </div>
    <div id="screen__mobile" class="section4__textbox">
      <div class="section4__maintext">
        <h1>Our Business</h1>
        <h1 class="section__textline"></h1>
      </div>
      <div class="section4__subtext">
        <p>{{ $t('mainSec4__mobile.text--1') }}</p>
        <p>{{ $t('mainSec4__mobile.text--2') }}</p>
      </div>
    </div>
    <div id="screen__mobile" class="section4__content">
      <div class="section4__content--cards" ref="cardsContainer" v-if="cardTitles">
        <swiper 
          :slides-per-view="1"
          :space-between="30"
          :loop="true"
          :modules="modules"
          class="mySwiper"
          >
          <swiper-slide v-for="(title, index) in cardTitles" :key="index"
            @click="goToBusiness(index)" class="section4__content--card" :class="`card--${index}`">
            <div class="card__detail">
              <h3>{{ title }}</h3>
              <p v-html="extendedCardDetails[index]"></p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';

export default {
  name: "MainSec4",
  data() {
    return {
      isAdjusting: false,       
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },
  computed: {
    cardTitles() {
      return [
        this.$t('mainSec4.cardTitle--1'),
        this.$t('mainSec4.cardTitle--2'),
        this.$t('mainSec4.cardTitle--3'),
      ];
    },
    cardDetails() {
      return [
        this.$t('mainSec4.cardDetail--1'),
        this.$t('mainSec4.cardDetail--2'),
        this.$t('mainSec4.cardDetail--3'),
      ];
    },
    extendedCardTitles() {
      return [...this.cardTitles];
    },
    extendedCardDetails() {
      return [...this.cardDetails];
    }
 
  },
  mounted() {
    this.setInitialScrollPosition();
    this.setupInfiniteScroll();
    const originalWarn = console.warn;
    console.warn = function(msg) {
      if (!msg.includes('Swiper Loop Warning')) {
        originalWarn.apply(console, arguments);
      }
    };
  },
  methods: {
    ...mapMutations("tab", ["changeTab"]),

    setInitialScrollPosition() {
      const container = this.$refs.cardsContainer;
      const cardWidth = container.firstElementChild.offsetWidth;
      container.scrollLeft = cardWidth * this.cardTitles.length;
    },
    setupInfiniteScroll() {
      const container = this.$refs.cardsContainer;
      const cardWidth = container.firstElementChild.offsetWidth;
      const numCards = this.cardTitles.length;
      const maxScrollLeft = cardWidth * (numCards * 2);

      container.addEventListener("scroll", () => {
        if (this.isAdjusting) return;

        if (container.scrollLeft >= maxScrollLeft) {
          this.isAdjusting = true;
          container.scrollLeft = cardWidth * numCards;
          setTimeout(() => { this.isAdjusting = false; }, 100);
        } else if (container.scrollLeft <= 0) {
          this.isAdjusting = true;
          container.scrollLeft = cardWidth * numCards - 1;
          setTimeout(() => { this.isAdjusting = false; }, 100);
        }
      });

      let isScrolling;
      container.addEventListener("scroll", () => {
        if (this.isAdjusting) return;

        window.clearTimeout(isScrolling);
        isScrolling = setTimeout(() => {
          if (container.scrollLeft % cardWidth !== 0) {
            this.isAdjusting = true;
            const closestIndex = Math.round(container.scrollLeft / cardWidth);
            container.scrollTo({
              left: closestIndex * cardWidth,
              behavior: 'smooth'
            });
            setTimeout(() => { this.isAdjusting = false; }, 500);
          }
        }, 66);
      });
    },

    goToBusiness(cardOrder) {
      const tab = cardOrder + 1
      this.changeTab(`tab${tab}`)
      this.$router.push('/business');
    }
  },
};
</script>
