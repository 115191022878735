<template>
    <div class="subtab__view--content subtab__view--content4">
        <h1 class="subtab__view4--title" id="screen__pc">
            {{ $t('peopleSubTab4.text--1') }}
            <div class="subtab__view4--title--span">{{ $t('peopleSubTab4.text--1__1') }}</div>
            {{ $t('peopleSubTab4.text--1__2') }}
            {{ $t('peopleSubTab4.text--1__3') }}
        </h1>
        <h1 class="subtab__view4--title" id="screen__mobile">
            <div>
                {{ $t('peopleSubTab4.text--1') }}
                <span class="subtab__view4--title--span">{{ $t('peopleSubTab4.text--1__1') }}</span>
                {{ $t('peopleSubTab4.text--1__2') }}
                <br />
                {{ $t('peopleSubTab4.text--1__3') }}
            </div>
            
        </h1>
        <img id="screen__pc" class="tabview__hero" src="../../assets/images/sub/outsourcing--17.jpg" alt="">
        <img id="screen__mobile" class="tabview__hero" src="../../assets/images/sub/outsourcing--17.jpg" alt="">
        <div class="subtab__view--textbox4" style="gap: 1rem;">
            <h2>{{ $t('peopleSubTab4.text--2') }}</h2>
            <br>
            <p class="first__content">
                {{ $t('peopleSubTab4.text--3') }}
                <br>
                {{ $t('peopleSubTab4.text--4') }}
                <br/>
                <br/>
                {{ $t('peopleSubTab4.text--5') }}
                <br/>
                <br/>
            </p>
        </div>
        <div class="subtab__view--textbox4" style="gap: 1rem;">
            <div v-if="lang == 'en'">
                <h3>{{ $t('peopleSubTab4.text--6__1') }}</h3>
                <div class="subtab4__tend">
                    <h5>{{ $t('peopleSubTab4.text--6__2') }}</h5>
                    <h5>{{ $t('peopleSubTab4.text--6__3') }}</h5>
                </div>
            </div>

            <h4>{{ $t('peopleSubTab4.text--6') }}</h4>
            <p>
                {{ $t('peopleSubTab4.text--7') }}
            </p>
            <h4>{{ $t('peopleSubTab4.text--8') }}</h4>
            <p>
                {{ $t('peopleSubTab4.text--9') }}
            </p>
            <h4>{{ $t('peopleSubTab4.text--10') }}</h4>
            <p>
                {{ $t('peopleSubTab4.text--11') }}
            </p>
            <br/>
            <br/>
            <br/>
        </div>
        <div class="subtab__view--textbox4" style="gap: 1rem;">
            <h3>{{ $t('peopleSubTab4.text--12') }}</h3>
            <h4>{{ $t('peopleSubTab4.text--13') }}</h4>
            <p>
                {{ $t('peopleSubTab4.text--14') }}
            </p>
            <h4>{{ $t('peopleSubTab4.text--15') }}</h4>
            <p>
                {{ $t('peopleSubTab4.text--16') }}
            </p>
            <h4>{{ $t('peopleSubTab4.text--17') }}</h4>
            <p>
                {{ $t('peopleSubTab4.text--18') }}
            </p>
            <br/>
            <br/>
            <br/>
        </div>
        <div class="subtab__view--textbox4 guide__content" style="gap: 1rem; border: none;">
            <h2>{{ $t('peopleSubTab4.text--19') }}</h2>
            <br />
            <p>{{ $t('peopleSubTab4.text--20') }}</p>
            <div class="subtab4__img--container">
                <img class="outsourcing--image outsourcing--16" src="../../assets/images/sub/outsourcing--18.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'PeopleSubTab4',
    computed: {
      ...mapState('lang', ['lang'])
    },
}
</script>