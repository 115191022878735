
export default {
    namespaced: true,
    state() {
        return {
            lang: 0
        };
    },
    mutations: {
        changeLang(state, lang) {
            state.lang = lang;
        },
    },
    actions: {
    },
};
