<template>
    <div>
        <p class="tabview__title">Location</p>
        <p class="tabview__subtitle">
            {{ $t('locationTab.text--1') }}
            <br id="screen__pc">
            {{ $t('locationTab.text--2') }}
        </p>
        <GMapMap class="tabview__map" :center="center" :zoom="zoom" map-type-id="roadmap"
            style="width: 100%; height: 400px">
            <GMapMarker :position="center" />
        </GMapMap>
        <div class="tabview__table" style="border: none;">
            <p class="tabview__table--title">{{ $t('locationTab.tableTitle--1') }}</p>
            <p>{{ $t('locationTab.tableText--1') }}</p>
        </div>
        <div class="tabview__table" style="border: none;">
            <p class="tabview__table--title">{{ $t('locationTab.tableTitle--2') }}</p>
            <p>02 - 568 - 4158</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LocationTab',
    data() {
        return {
            center: { lat: 37.5089152, lng: 127.0429776 },
            zoom: 19
        };
    },
};
</script>

<style scoped>
.tabview__map {
    /* height: 460px; */
    width: 100%;
    margin-bottom: 2rem;
}

.tabview__table:last-child {
    margin-bottom: 1.5rem;
}
</style>
