<template>
    <p class="tabview__title">Company</p>
    <p id="screen__mobile" class="tabview__subtitle">{{ $t('companyTab.text--1') }}</p>
    <img id="screen__pc" class="tabview__hero" src="../../assets/images/sub/company__logo.png" alt="">
    <img id="screen__mobile" class="tabview__hero" src="../../assets/images/sub/company__logo--mobile.png" alt="">
    <p class="tabview__details">
        {{ $t('companyTab.text--2') }}
        <br id="screen__mobile">
        {{ $t('companyTab.text--3') }}
        <br>
        {{ $t('companyTab.text--4') }}
        <br id="screen__mobile">
        {{ $t('companyTab.text--5') }}
        <br id="screen__mobile">
        {{ $t('companyTab.text--6') }}
        <br><br>
        {{ $t('companyTab.text--7') }}
        <br id="screen__mobile">
        {{ $t('companyTab.text--8') }}
        <br id="screen__pc">
        {{ $t('companyTab.text--9') }}
    </p>
    <div class="tabview__table">
        <p class="tabview__table--title">{{ $t('companyTab.tableTitle--1') }}</p>
        <p>{{ $t('companyTab.tableText--1') }}</p>
    </div>
    <div class="tabview__table">
        <p class="tabview__table--title">{{ $t('companyTab.tableTitle--2') }}</p>
        <p>{{ $t('companyTab.tableText--2') }}</p>
    </div>
    <div class="tabview__table">
        <p class="tabview__table--title">{{ $t('companyTab.tableTitle--3') }}</p>
        <p>{{ $t('companyTab.tableText--3') }}</p>
    </div>
    <div class="tabview__table">
        <p class="tabview__table--title">{{ $t('companyTab.tableTitle--4') }}</p>
        <p>
            {{ $t('companyTab.tableText--4') }}
            <br>
            {{ $t('companyTab.tableText--5') }}
        </p>
    </div>
    <div class="tabview__table">
        <p class="tabview__table--title">{{ $t('companyTab.tableTitle--5') }}</p>
        <p>{{ $t('companyTab.tableText--6') }}</p>
    </div>
    <div class="tabview__table">
        <p class="tabview__table--title">{{ $t('companyTab.tableTitle--6') }}</p>
        <p>
            {{ $t('companyTab.tableText--7') }}
            <br id="screen__mobile">
            {{ $t('companyTab.tableText--8') }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'CompanyTab',
}
</script>