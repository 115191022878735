<template>
    <div class="subtab__view--content">
        <img class="brand--image brand--1" src="../../assets/images/sub/brand--1.png" alt="">

        <div id="screen__pc" class="subtab__view--textbox1" style="border: none;">
            <h2>BRAND KEY VALUE</h2>
            <p style="padding-bottom: 0;">
                {{ $t('brandSubTab.text--1') }}
                <br>
                {{ $t('brandSubTab.text--2') }}
            </p>
            <p style="padding-bottom: 0;">
                {{ $t('brandSubTab.text--3') }}
                <br>
                {{ $t('brandSubTab.text--4') }}
            </p>
        </div>
        <div id="screen__pc" class="subtab__view--wrap">
            <div class="subtab__view--textbox2 brand--textbox">
                <h3>
                    #1. High Quality
                    <br>
                    {{ $t('brandSubTab.text--5') }}
                </h3>
                <p>
                    {{ $t('brandSubTab.text--6') }}
                    <br>
                    {{ $t('brandSubTab.text--7') }}
                </p>
            </div>
            <div class="subtab__view--textbox2 brand--textbox">
                <h3>
                    #2. Value Orlentation
                    <br>
                    {{ $t('brandSubTab.text--8') }}
                </h3>
                <p>
                    {{ $t('brandSubTab.text--9') }}
                    <br>
                    {{ $t('brandSubTab.text--10') }}
                </p>
            </div>
            <div class="subtab__view--textbox2 brand--textbox">
                <h3>
                    #3. Newness
                    <br>
                    Customer-centrlc product
                </h3>
                <p>
                    {{ $t('brandSubTab.text--11') }}
                    <br>
                    {{ $t('brandSubTab.text--13') }}
                </p>
            </div>
        </div>

        <div id="screen__mobile" class="subtab__view--textbox1" style="border: none;">
            <h2>BRAND KEY VALUE</h2>
            <p style="padding-bottom: 0;">
                {{ $t('brandSubTab__mobile.text--1') }}
                <br>
                {{ $t('brandSubTab__mobile.text--2') }}
                <br>
                {{ $t('brandSubTab__mobile.text--3') }}
                <br>
                {{ $t('brandSubTab__mobile.text--4') }}
            </p>
            <p style="padding-bottom: 0;">
                {{ $t('brandSubTab__mobile.text--5') }}
                <br>
                {{ $t('brandSubTab__mobile.text--6') }}
                <br>
                {{ $t('brandSubTab__mobile.text--7') }}
                <br>
                {{ $t('brandSubTab__mobile.text--8') }}
            </p>
            <p>
                {{ $t('brandSubTab__mobile.text--9') }}
                <br>
                {{ $t('brandSubTab__mobile.text--10') }}
                <br>
                {{ $t('brandSubTab__mobile.text--11') }}
            </p>
        </div>
        <div id="screen__mobile" class="subtab__view--wrap">
            <div class="subtab__view--textbox2 brand--textbox">
                <h3>
                    #1. High Quality
                    <br>
                    {{ $t('brandSubTab__mobile.text--12') }}
                </h3>
                <p>
                    {{ $t('brandSubTab__mobile.text--13') }}
                    <br>
                    {{ $t('brandSubTab__mobile.text--14') }}
                    <br>
                    {{ $t('brandSubTab__mobile.text--15') }}
                </p>
            </div>
            <div class="subtab__view--textbox2 brand--textbox">
                <h3>
                    #2. Value Orlentation
                    <br>
                    {{ $t('brandSubTab__mobile.text--16') }}
                    <br>
                    {{ $t('brandSubTab__mobile.text--17') }}
                </h3>
                <p>
                    {{ $t('brandSubTab__mobile.text--18') }}
                    <br>
                    {{ $t('brandSubTab__mobile.text--19') }}
                    <br>
                    {{ $t('brandSubTab__mobile.text--20') }}
                </p>
            </div>
            <div class="subtab__view--textbox2 brand--textbox">
                <h3>
                    #3. Newness
                    <br>
                    Customer-centrlc product
                </h3>
                <p>
                    {{ $t('brandSubTab__mobile.text--21') }}
                    <br>
                    {{ $t('brandSubTab__mobile.text--22') }}
                </p>
            </div>
        </div>

        <button class="product--button">{{ $t('productSubTab.button') }}<span>></span></button>
    </div>
</template>

<script>
export default {
    name: 'BrandSubTab',
}
</script>