<template>
    <div id="sub__page">
        <div :class="['sub__page--textbox', heroClass]">
            <h1>Our Business</h1>
            <p>{{ $t('businessDepth.text--1') }}</p>
        </div>
        <TabView :currentTab="currentTab" :tabs="tabs"  @update:currentTab="updateTab">
            <template #tab1>
                <p class="tabview__title">Outsourcing</p>
                <SubTabView  :subtabs="subTabs1" @update:currentTab="updateTab">
                    <template #subtab1>
                        <PeopleSubTab1 />
                    </template>
                    <template #subtab2>
                        <PeopleSubTab2 />
                    </template>
                    <template #subtab3>
                        <PeopleSubTab3 />
                    </template>
                    <template #subtab4>
                        <PeopleSubTab4 />
                    </template>
                </SubTabView>
            </template>
            <template #tab2>
                <p class="tabview__title">OVERSEAS PRODUCT SOURCING</p>
                <SubTabView :subtabs="subTabs2">
                    <template #subtab1>
                        <ProductSubTab />
                    </template>
                </SubTabView>
            </template>
            <template #tab3>
                <p class="tabview__title">BRAND OPERATION</p>
                <SubTabView :subtabs="subTabs3">
                    <template #subtab1>
                        <BrandSubTab />
                    </template>
                </SubTabView>
            </template>
        </TabView>
    </div>
    <MainSec6 />
</template>

<script>
import TabView from "@/components/sub/TabView.vue";
import SubTabView from "@/components/sub/SubTabView.vue";
import PeopleSubTab1 from "@/components/business/PeopleSubTab1.vue";
import PeopleSubTab2 from "@/components/business/PeopleSubTab2.vue";
import PeopleSubTab3 from "@/components/business/PeopleSubTab3.vue";
import PeopleSubTab4 from "@/components/business/PeopleSubTab4.vue";
import ProductSubTab from "@/components/business/ProductSubTab.vue";
import BrandSubTab from "@/components/business/BrandSubTab.vue";
import MainSec6 from "@/components/main/MainSec6.vue";
import { mapState } from "vuex";

export default {
    name: 'BusinessDepth',
    data() {
        return {
            currentTab: '',
            subTabs2: [
                { name: 'subtab1', label: 'FAMOUS SODA CO' }
            ],
            subTabs3: [
                { name: 'subtab1', label: 'SOXOVRWTV' }
            ]
        }
    },
    computed: {
        ...mapState('tab', ["tab"]),

        tabs() {
            return [
                { name: 'tab1', label: this.$t('businessDepth.tab--1') },
                { name: 'tab2', label: this.$t('businessDepth.tab--2') },
                { name: 'tab3', label: this.$t('businessDepth.tab--3') }
            ];
        },
        subTabs1() {
            return [
                { name: 'subtab1', label: this.$t('businessDepth.subtab--1') },
                { name: 'subtab2', label: this.$t('businessDepth.subtab--2') },
                { name: 'subtab3', label: this.$t('businessDepth.subtab--3') },
                { name: 'subtab4', label: this.$t('businessDepth.subtab--4') },
            ];
        },
        heroClass() {
            switch (this.currentTab) {
                case 'tab1':
                    return 'people__hero';
                case 'tab2':
                    return 'product__hero';
                case 'tab3':
                    return 'brand__hero';
                default:
                    return '';
            }
        },
    },
    mounted() {
        this.loadTab();
    },
    methods: {
        loadTab() {
            if (this.tab) {
                this.currentTab = this.tab;
            }
            else {
                this.currentTab = 'tab1';
            }
        },
        updateTab(tab) {
            this.currentTab = tab;
        },
    },
    components: {
        TabView,
        SubTabView,
        PeopleSubTab1,
        PeopleSubTab2,
        PeopleSubTab3,
        PeopleSubTab4,
        ProductSubTab,
        BrandSubTab,
        MainSec6
    },
}
</script>