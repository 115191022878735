<template>
    <div class="subtab__view--content">
        <img id="screen__pc" class="tabview__hero" src="../../assets/images/sub/outsourcing--1.png" alt="">
        <img id="screen__mobile" class="tabview__hero" src="../../assets/images/sub/outsourcing--1--mobile.png" alt="">
        <h1>{{ $t('peopleSubTab1.text--1') }}</h1>
        <img class="outsourcing--image outsourcing--2" src="../../assets/images/sub/outsourcing--2.png" alt="">
        <div class="subtab__view--textbox1">
            <h2>
                {{ $t('peopleSubTab1.text--2') }}
                <br>
                {{ $t('peopleSubTab1.text--3') }}
            </h2>
            <h3>{{ $t('peopleSubTab1.text--4') }}</h3>
            <p id="screen__pc">
                {{ $t('peopleSubTab1.text--5') }}
                <br>
                {{ $t('peopleSubTab1.text--6') }}
                <br>
                {{ $t('peopleSubTab1.text--7') }}
            </p>
            <p id="screen__mobile">
                {{ $t('peopleSubTab1__mobile.text--1') }}
                <br>
                {{ $t('peopleSubTab1__mobile.text--2') }}
                <br>
                {{ $t('peopleSubTab1__mobile.text--3') }}
                <br>
                {{ $t('peopleSubTab1__mobile.text--4') }}
            </p>
        </div>
        <img class="outsourcing--image outsourcing--3" src="../../assets/images/sub/outsourcing--3.jpg" alt="">
        <div class="subtab__view--wrap">
            <div class="subtab__view--textbox2">
                <h3>{{ $t('peopleSubTab1.text--8') }}</h3>
                <p id="screen__pc">
                    {{ $t('peopleSubTab1.text--9') }}
                    <br>
                    {{ $t('peopleSubTab1.text--10') }}
                </p>
                <p id="screen__mobile">
                    {{ $t('peopleSubTab1__mobile.text--5') }}
                    <br>
                    {{ $t('peopleSubTab1__mobile.text--6') }}
                    <br>
                    {{ $t('peopleSubTab1__mobile.text--7') }}
                    <br>
                    {{ $t('peopleSubTab1__mobile.text--8') }}
                    <br>
                    {{ $t('peopleSubTab1__mobile.text--9') }}
                </p>
            </div>
            <div class="subtab__view--textbox2">
                <h3>{{ $t('peopleSubTab1.text--11') }}</h3>
                <p id="screen__pc">
                    {{ $t('peopleSubTab1.text--12') }}
                    <br>
                    {{ $t('peopleSubTab1.text--13') }}
                </p>
                <p id="screen__mobile">
                    {{ $t('peopleSubTab1__mobile.text--10') }}
                    <br>
                    {{ $t('peopleSubTab1__mobile.text--11') }}
                    <br>
                    {{ $t('peopleSubTab1__mobile.text--12') }}
                    <br>
                    {{ $t('peopleSubTab1__mobile.text--13') }}
                </p>
            </div>
            <div class="subtab__view--textbox2">
                <h3>{{ $t('peopleSubTab1.text--14') }}</h3>
                <div id="screen__pc" style="flex-direction: column;">
                    <p>
                        <span style="color: #ff7a00;">{{ $t('peopleSubTab1.text--15') }}</span>{{
                            $t('peopleSubTab1.text--16') }}
                        <br>
                        {{ $t('peopleSubTab1.text--17') }}
                    </p>
                    <br>
                    <p>
                        <span style="color: #ff7a00;">{{ $t('peopleSubTab1.text--18') }}</span>{{
                            $t('peopleSubTab1.text--19') }}
                        <br>
                        {{ $t('peopleSubTab1.text--20') }}
                    </p>
                    <br>
                    <p>
                        <span style="color: #ff7a00;">{{ $t('peopleSubTab1.text--21') }}</span>{{
                            $t('peopleSubTab1.text--22') }}
                        <br>
                        {{ $t('peopleSubTab1.text--23') }}
                    </p>
                </div>
                <div id="screen__mobile" style="flex-direction: column;">
                    <p>
                        <span style="color: #ff7a00;">{{ $t('peopleSubTab1.text--15') }}</span>{{
                            $t('peopleSubTab1.text--16') }}
                        <br>
                        {{ $t('peopleSubTab1__mobile.text--14') }}
                        <br>
                        {{ $t('peopleSubTab1__mobile.text--15') }}
                        <br>
                        {{ $t('peopleSubTab1__mobile.text--16') }}
                    </p>
                    <br>
                    <p>
                        <span style="color: #ff7a00;">{{ $t('peopleSubTab1.text--18') }}</span>{{
                            $t('peopleSubTab1.text--19') }}
                        <br>
                        {{ $t('peopleSubTab1__mobile.text--17') }}
                        <br>
                        {{ $t('peopleSubTab1__mobile.text--18') }}
                    </p>
                    <br>
                    <p>
                        <span style="color: #ff7a00;">{{ $t('peopleSubTab1.text--21') }}</span>{{
                            $t('peopleSubTab1.text--22') }}
                        <br>
                        {{ $t('peopleSubTab1__mobile.text--19') }}
                    </p>
                </div>
            </div>
        </div>
        <div class="subtab__view--textbox1">
            <img class=" outsourcing--image outsourcing--4" src="../../assets/images/sub/outsourcing--4.png" alt="">
        </div>
        <div v-if="isKoreanLanguage" class="subtab__view--textbox1" style="border: none; margin: 0;">
            <h2>{{ $t('peopleSubTab1.text--24') }}</h2>
            <img class="outsourcing--image outsourcing--5" src="../../assets/images/sub/outsourcing--5.png" alt="">
        </div>
        <div v-else class="subtab__view--textbox1" style="border: none; margin: 0;">
            <h2>{{ $t('peopleSubTab1.text--24') }}</h2>
            <img class="outsourcing--image outsourcing--5" src="../../assets/images/sub/outsourcing--5--en.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'PeopleSubTab1',
    computed: {
        isKoreanLanguage() {
            return this.$i18n.locale === 'ko';
        }
    }
}
</script>