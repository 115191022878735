// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import CompanyDepth from '@/components/company/CompanyDepth.vue';
import BusinessDepth from '@/components/business/BusinessDepth.vue';
import NewsDepth from '@/components/news/NewsDepth.vue';
import DetailDepth from '@/components/news/DetailDepth.vue';
import ContactDepth from '@/components/contact/ContactDepth.vue';

const routes = [
    {
        path: '/company',
        name: 'CompanyDepth',
        component: CompanyDepth
    },
    {
        path: '/business',
        name: 'BusinessDepth',
        component: BusinessDepth
    },
    {
        path: '/news',
        name: 'NewsDepth',
        component: NewsDepth,
    },
    {
        path: '/:type/:id/:tab',
        name: 'DetailDepth',
        component: DetailDepth,
    },
    {
        path: '/contact',
        name: 'ContactDepth',
        component: ContactDepth
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
