<template>
    <div id="sub__page">
        <div class="sub__page--textbox company__hero">
            <h1>Company</h1>
            <h1 id="screen__pc" class="section__textline"></h1>
            <p>{{ $t('companyDepth.text--1') }}</p>
        </div>
        <TabView :tabs="tabs">
            <template #tab1>
                <CompanyTab />
            </template>
            <template #tab2>
                <LocationTab />
            </template>
        </TabView>
    </div>
    <MainSec6 />
</template>

<script>
import TabView from "@/components/sub/TabView.vue";
import CompanyTab from "@/components/company/CompanyTab.vue";
import LocationTab from "@/components/company/LocationTab.vue";
import MainSec6 from "@/components/main/MainSec6.vue";

export default {
    name: 'CompanyDepth',
    components: {
        TabView,
        CompanyTab,
        LocationTab,
        MainSec6
    },
    computed: {
        tabs() {
            return [
                { name: 'tab1', label: this.$t('companyDepth.tab--1') },
                { name: 'tab2', label: this.$t('companyDepth.tab--2') }
            ];
        }
    }
}
</script>
