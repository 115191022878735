<template>
  <div id="main__section" class="section1">
    <div class="background-container">
      <div class="slider">
        <swiper
          ref="mySwiper"
          :slides-per-view="1"
          :space-between="30"
           :loop="true"
          :autoplay="{ delay: 2000 }"
          :speed="800"
          :modules="modules"
          class="mySwiper"
          @slideChange="slideChangeTransitionStart"
          @swiper="setSwiper"
        >
          <swiper-slide v-for="(img, i) in images" :key="i" :class="{'blur-background': i == 1 }">
            <img :src="img" />
          </swiper-slide>
        </swiper>

      
      </div>

      <div class="section1__body">
        <div class="section1__maintext">
          <h1>Best</h1>
          <h1>Sourcing</h1>
          <h1>Partners</h1>
          <h1 class="section__textline"></h1>
        </div>
        <div id="screen__pc" class="section1__subtext">
          <p>{{ $t('mainSec1.text--1') }}</p>
          <p>{{ $t('mainSec1.text--2') }}</p>
        </div>
        <div id="screen__mobile" class="section1__subtext">
          <p>{{ $t('mainSec1__mobile.text--1') }}</p>
          <p>{{ $t('mainSec1__mobile.text--2') }}</p>
          <p>{{ $t('mainSec1__mobile.text--3') }}</p>
        </div>
        <div class="swiper-btn-container">
          <div class="swiper-button-prev swiper-btn-prev" @click="prevSlide"></div>
          <div class="swiper-button-next swiper-btn-next" @click="nextSlide"></div>
        </div>
        
        
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

export default {
  name: "MainSec1",
  data() {
    return {
      images: [
        require('@/assets/images/main/section1--bg1.png'),
        require('@/assets/images/main/section1--bg2.png'),
        require('@/assets/images/main/section1--bg3.png'),
      ],
      swiperInstance: null, // Swiper 인스턴스를 저장할 변수
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination, Navigation, Autoplay],
    };
  },
  methods: {
    setSwiper(swiper) {
      this.swiperInstance = swiper; // Swiper 인스턴스를 저장
    },
    prevSlide() {
      if (this.swiperInstance) {
        this.swiperInstance.slidePrev(); // 이전 슬라이드로 이동
      }
    },
    nextSlide() {
      if (this.swiperInstance) {
        this.swiperInstance.slideNext(); // 다음 슬라이드로 이동
      }
    },
  },
};
</script>

<style scoped>
.background-container {
  position: absolute;
  width: 100%;
  height: 120%;
  padding: 0;
  margin: 0;
}

.slider {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  transition: transform 1s ease;
}

.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mySwiper {
  width: 100%;
  height: 100%;
  border: none;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.swiper-slide img {
  width: 100%;
  object-fit: cover;
  display: block; 
}

</style>

<style>
.blur-background::after {
  content: '';
  position: absolute; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3); 
  pointer-events: none;
}

.swiper-btn-container {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.swiper-button-prev::after ,
.swiper-button-next::after{
    font-size: 20px !important;
}

.swiper-button-prev ,
.swiper-button-next {
    position: relative;
    color: var(--font-color-default);
    z-index: 10;
    font-size: 18px !important;
    font-weight: 600;
    margin-top: 20px;
    left: 0;
}




</style>