<template>
    <div class="subtab__view--content">
        <img id="screen__pc" class="tabview__hero" src="../../assets/images/sub/outsourcing--6.png" alt="">
        <img id="screen__mobile" class="tabview__hero" src="../../assets/images/sub/outsourcing--6--mobile.png" alt="">
        <h1>{{ $t('peopleSubTab2.text--1') }} <br /> {{ $t('peopleSubTab2.text--1--1') }}</h1>
        <div class="subtab__view--textbox1--tab2">
            <h2>{{ $t('peopleSubTab2.text--2') }} <br />
                {{ $t('peopleSubTab2.text--2--1') }} <br />
                {{ $t('peopleSubTab2.text--2--2') }} 
            </h2>
        </div>
        <div id="screen__pc" class="subtab__view--textbox1">
            <p style="padding-bottom: 0;">
                {{ $t('peopleSubTab2.text--7') }}
                <br>
                {{ $t('peopleSubTab2.text--8') }}
                <br>
                <span style="color: #FF4040;">{{ $t('peopleSubTab2.text--9--1') }}</span>
                {{ $t('peopleSubTab2.text--9') }}
            </p>
            <p style="padding-bottom: 0;">
                {{ $t('peopleSubTab2.text--10') }}
                <br>
                {{ $t('peopleSubTab2.text--11') }}
                <br>
                {{ $t('peopleSubTab2.text--12') }}
            </p>
            <p>
                {{ $t('peopleSubTab2.text--14') }}
                <br>
                {{ $t('peopleSubTab2.text--15') }}
                <br>
                {{ $t('peopleSubTab2.text--16') }}
            </p>
        </div>
        <div id="screen__mobile" class="subtab__view--textbox1">
            <p style="padding-bottom: 0;">
                {{ $t('peopleSubTab2__mobile.text--1') }}
                <span style="color: #FF4040;">{{ $t('peopleSubTab2__mobile.text--2') }}</span>
                {{ $t('peopleSubTab2__mobile.text--3') }}
            </p>
            <p style="padding-bottom: 0;">
                {{ $t('peopleSubTab2__mobile.text--4') }}
            </p>
            <p>
                {{ $t('peopleSubTab2__mobile.text--5') }}
            </p>
        </div>
        <div id="screen__mobile" class="subtab__view--textbox1">
            <h2>{{ $t('peopleSubTab2.text--23') }}</h2>
            <div class="business__welder">
                <img src="../../assets/images/sub/welder--1.png"/>
                <div class="business__welder--container">
                    <div class="business__welder--content">
                        <h3>{{ $t('peopleSubTab2.text--24') }}</h3>
                        <p style="padding-bottom: 0;">
                            {{ $t('peopleSubTab2.text--25') }}
                            <br>
                            {{ $t('peopleSubTab2.text--26') }}
                            <br>
                            {{ $t('peopleSubTab2.text--27') }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="business__welder">
                <img src="../../assets/images/sub/welder--2.png"/>
                <div class="business__welder--container">
                    <div class="business__welder--content">
                        <h3>{{ $t('peopleSubTab2.text--28') }}</h3>
                        <p style="padding-bottom: 0;">
                            {{ $t('peopleSubTab2.text--29') }}
                            <br>
                            {{ $t('peopleSubTab2.text--30') }}
                            <br>
                            {{ $t('peopleSubTab2.text--31') }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="business__welder">
                <img src="../../assets/images/sub/welder--3.png"/>
                <div class="business__welder--container">
                    <div class="business__welder--content">
                        <h3>{{ $t('peopleSubTab2.text--32') }}</h3>
                        <p>
                            {{ $t('peopleSubTab2.text--33') }}
                            <br>
                            {{ $t('peopleSubTab2.text--34') }}
                            <br>
                            {{ $t('peopleSubTab2.text--35') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div id="screen__pc" class="subtab__view--textbox1">
            <h2>{{ $t('peopleSubTab2.text--23') }}</h2>
            <div class="business__welder">
                <img src="../../assets/images/sub/welder--1.png"/>
                <div class="business__welder--container">
                    <div class="business__welder--content">
                        <h3>{{ $t('peopleSubTab2.text--24') }}</h3>
                        <p style="padding-bottom: 0;">
                            {{ $t('peopleSubTab2.text--25') }}
                            <br>
                            {{ $t('peopleSubTab2.text--26') }}
                            <br>
                            {{ $t('peopleSubTab2.text--27') }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="business__welder">
                <img src="../../assets/images/sub/welder--2.png"/>
                <div class="business__welder--container">
                    <div class="business__welder--content">
                        <h3>{{ $t('peopleSubTab2.text--28') }}</h3>
                        <p style="padding-bottom: 0;">
                            {{ $t('peopleSubTab2.text--29') }}
                            <br>
                            {{ $t('peopleSubTab2.text--30') }}
                            <br>
                            {{ $t('peopleSubTab2.text--31') }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="business__welder">
                <img src="../../assets/images/sub/welder--3.png"/>
                <div class="business__welder--container">
                    <div class="business__welder--content">
                        <h3>{{ $t('peopleSubTab2.text--32') }}</h3>
                        <p>
                            {{ $t('peopleSubTab2.text--33') }}
                            <br>
                            {{ $t('peopleSubTab2.text--34') }}
                            <br>
                            {{ $t('peopleSubTab2.text--35') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="subtab__view--textbox1">
            <h2>{{ $t('peopleSubTab2.text--20') }}</h2>
            <img class="outsourcing--image outsourcing--9" src="../../assets/images/sub/outsourcing--9.png" alt="">
        </div>
        <div class="subtab__view--textbox1">
            <h2>{{ $t('peopleSubTab2.text--21') }}</h2>
            <img class="outsourcing--image outsourcing--10" src="../../assets/images/sub/outsourcing--10.png" alt="">
        </div>
        <div class="subtab__view--textbox1">
            <h2>{{ $t('peopleSubTab2.text--22') }}</h2>
            <img v-if="isKoreanLanguage" class="outsourcing--image outsourcing--11"
                src="../../assets/images/sub/outsourcing--11.png" alt="">
            <img v-else class="outsourcing--image outsourcing--11"
                src="../../assets/images/sub/outsourcing--11--en.png">
        </div>
        <img class="outsourcing--image outsourcing--11" src="../../assets/images/sub/outsourcing--12.jpg" alt="">

    </div>
</template>

<script>
export default {
    name: 'PeopleSubTab2',
    computed: {
        isKoreanLanguage() {
            return this.$i18n.locale === 'ko';
        }
    }
}
</script>