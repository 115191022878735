<template>
    <div class="agree__modal" v-if="isOpen">
        <div class="agree__modal--main">
            <div class="x-icon" @click="$emit('close')"></div>
            <h3>{{ $t('agreeModal.title') }}</h3>
            <p id="scrollable-paragraph" class="scrollable-paragraph">
                {{ $t('agreeModal.text--1') }}
                <br>
                {{ $t('agreeModal.text--2') }}
                <br><br>
                {{ $t('agreeModal.text--3') }}
                <br>
                {{ $t('agreeModal.text--4') }}
                <br><br>
                {{ $t('agreeModal.text--5') }}
                <br>
                {{ $t('agreeModal.text--6') }}
                <br><br>
                {{ $t('agreeModal.text--7') }}
                <br>
                {{ $t('agreeModal.text--8') }}
                <br><br>
                {{ $t('agreeModal.text--9') }}
                <br><br>
                {{ $t('agreeModal.text--10') }}
                <br>
                {{ $t('agreeModal.text--11') }}
                <br>
                {{ $t('agreeModal.text--12') }}
                <br>
                {{ $t('agreeModal.text--13') }}
                <br><br>
                {{ $t('agreeModal.text--14') }}
                <br>
                {{ $t('agreeModal.text--15') }}
                <br>
                {{ $t('agreeModal.text--16') }}
                <br>
                {{ $t('agreeModal.text--17') }}
                <br>
                {{ $t('agreeModal.text--18') }}
                <br>
                {{ $t('agreeModal.text--19') }}
                <br>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AgreeModal',
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
    },
    mounted() {
        this.updateBodyOverflow(this.isOpen);
    },
    watch: {
        isOpen(newVal) {
            this.updateBodyOverflow(newVal);
        },
    },
    methods: {
        updateBodyOverflow(isOpen) {
            if (isOpen) {
                document.body.style.overflow = 'hidden';
                document.getElementById('scrollable-paragraph').addEventListener('wheel', this.enableScroll, { passive: false });
                document.getElementById('scrollable-paragraph').addEventListener('touchmove', this.enableScroll, { passive: false });
            } else {
                document.body.style.overflow = 'auto';
                document.getElementById('scrollable-paragraph').removeEventListener('wheel', this.enableScroll);
                document.getElementById('scrollable-paragraph').removeEventListener('touchmove', this.enableScroll);
            }
        },
        enableScroll(event) {
            event.stopPropagation();
        },
    },
};

</script>