<template>
    <div id="sub__page">
        <div :class="['sub__page--textbox', currentTab === 'tab1' ? 'notice__hero' : 'news__hero']">
            <h1 class="sub__page--textkr">{{ tabLabels[currentTab] }}</h1>
            <h1 id="screen__pc" class="section__textline"></h1>
            <p :class="currentTab === 'tab1' ? 'notice__hero--subtext' : ''">{{ currentMessage }}</p>
        </div>
        <div class="news-detail-container" > 
            <div class="news-title" id="screen__pc">
                <h1>{{ post.title }}</h1>
                <p>{{ post.created_at }}</p>
            </div>
            <div class="news-title" id="screen__mobile">
                <div class="news-title-mobile">
                    <h1>{{ post.title }}</h1>
                    <div class="news-title-time">
                        <p>{{ post.created_at }}</p>
                    </div>
                </div>
            </div>
            <hr>
            <div class="news-content">
                {{ post.body }}

            </div>
        </div>
    </div>
    <MainSec6 />
</template>

<script>
import axios from 'axios';
import MainSec6 from "@/components/main/MainSec6.vue";

export default {
    name: 'NewsDepth',
    components: {
        MainSec6
    },
     data() {
        return {
            posts: {
                'tab1': [],
                'tab2': []
            },
            currentTab: 'tab1',
            post: {},
            currentPage: {
                'tab1': 1,
                'tab2': 1
            },
        };
    },
    computed: {
        tabLabels() {
            return {
                tab1: this.$t('newsDepth.tab--1'),
                tab2: this.$t('newsDepth.tab--2')
            };
        },
        currentMessage() {
            return this.currentTab === 'tab1'
                ? this.$t('newsDepth.text--1')
                : this.$t('newsDepth.text--2');
        },
        
    },
    methods: {
        async fetchPosts() {
            let tab = this.$route.params.tab
            this.loading = true;
            try {
                let response 
                
                if (this.$route.params.type == 'news') {
                    response = await axios.get(`https://bspgbl.com/admin/post/get_posts_news.php?page=${tab}`);
                } else {
                    response = await axios.get(`https://bspgbl.com/admin/post/get_posts_notice.php?page=${tab}`);
                }
                for (let post of response.data.posts) {
                    if (post.id == this.$route.params.id) {
                        this.post = post
                        if (post.type == 'notice') {
                            this.currentTab = 'tab1'
                        } else {
                            this.currentTab = 'tab2'
                        }
                    }
                }
                this.incrementViews(this.$route.params.id)
            } catch (error) {
                this.error = 'Failed to load posts.';
            } finally {
                this.loading = false;
            }
        },
        async incrementViews(postId, postType) {
            const now = Date.now();
            const lastViewTime = localStorage.getItem(`lastViewTime_${postId}`);

            // 1분 이내에 중복 조회 방지
            if (lastViewTime && now - lastViewTime < 60000) {
                return;
            }

            localStorage.setItem(`lastViewTime_${postId}`, now);

            try {
                const response = await axios.post('https://bspgbl.com/admin/post/increment_views.php', { id: postId });
                if (response.data.success) {
                    // currentTab이 undefined가 아닌지 확인 후 type과 id가 맞는 post의 views를 증가
                    if (this.currentTab !== undefined && this.posts && this.posts[this.currentTab]) {
                        this.posts = this.posts.map(post => {
                            if (post.id === postId && post.type === postType) {
                                return { ...post, views: Number(post.views) + 1 };
                            }
                            return post;
                        });
                    } else {
                        return;
                        // console.error('this.currentTab or this.posts[this.currentTab] is undefined');
                    }
                } else {
                    return;
                    // console.error('Failed to increment views:', response.data.error);
                }
            } catch (error) {
                return;
                // console.error('Failed to increment views:', error);
            }
        }
    },
    created() {
        document.documentElement.scrollTop;
       this.fetchPosts();
    },
    beforeRouteLeave() {
        window.location.href = '/news';
    },
};
</script>