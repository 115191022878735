<template>
    <div class="subtab__view--content">
        <img class="product--bg" src="../../assets/images/sub/product--bg.png" alt="">
        <img class="product--image product--1" src="../../assets/images/sub/product--1.png" alt="">

        <div id="screen__pc" class="subtab__view--textbox1" style="gap: 0.5rem; border: none;">
            <img class="product--image product--2" src="../../assets/images/sub/product--2.png" alt="">
            <p>
                {{ $t('productSubTab.text--1') }}
                <br>
                {{ $t('productSubTab.text--2') }}
            </p>
            <p>
                {{ $t('productSubTab.text--3') }}
                <br>
                {{ $t('productSubTab.text--4') }}
                <br>
                {{ $t('productSubTab.text--5') }}
            </p>
        </div>
        <div id="screen__pc" class="subtab__view--textbox1" style="gap: 0.5rem; border: none;">
            <img class="product--image product--3" src="../../assets/images/sub/product--3.png" alt="">
            <h3>#ZEROg SUGAR</h3>
            <p>{{ $t('productSubTab.text--6') }}</p>
            <h3>#NATURALLY FLAVORED</h3>
            <p>{{ $t('productSubTab.text--7') }}</p>
            <h3>#AUSTRALLIAN BORN</h3>
            <p>
                {{ $t('productSubTab.text--8') }}
            </p>
        </div>

        <div id="screen__moblie" class="subtab__view--textbox1" style="gap: 0.5rem; border: none;">
            <img class="product--image product--2" src="../../assets/images/sub/product--2.png" alt="">
            <p>
                {{ $t('productSubTab__mobile.text--1') }}
                <br>
                {{ $t('productSubTab__mobile.text--2') }}
                <br>
                {{ $t('productSubTab__mobile.text--3') }}
                <br>
                {{ $t('productSubTab__mobile.text--4') }}
                <br>
                {{ $t('productSubTab__mobile.text--5') }}
            </p>
            <p>
                {{ $t('productSubTab__mobile.text--6') }}
                <br>
                {{ $t('productSubTab__mobile.text--7') }}
                <br>
                {{ $t('productSubTab__mobile.text--8') }}
                <br>
                {{ $t('productSubTab__mobile.text--9') }}
                <br>
                {{ $t('productSubTab__mobile.text--10') }}
                <br>
                {{ $t('productSubTab__mobile.text--11') }}
            </p>
        </div>
        <div id="screen__mobile" class="subtab__view--textbox1" style="gap: 0.5rem; border: none;">
            <img class="product--image product--3" src="../../assets/images/sub/product--3.png" alt="">
            <h3>#ZEROg SUGAR</h3>
            <p>
                {{ $t('productSubTab__mobile.text--12') }}
                <br>
                {{ $t('productSubTab__mobile.text--13') }}
            </p>
            <h3>#NATURALLY FLAVORED</h3>
            <p>
                {{ $t('productSubTab__mobile.text--14') }}
                <br>
                {{ $t('productSubTab__mobile.text--15') }}
            </p>
            <h3>#AUSTRALLIAN BORN</h3>
            <p>
                {{ $t('productSubTab__mobile.text--16') }}
                <br>
                {{ $t('productSubTab__mobile.text--17') }}
            </p>
        </div>

        <div v-if="isKoreanLanguage"
            style="width: 80%; display: flex; flex-direction: column; align-items: center; gap: 4rem; padding: 0 10%">
            <img id="screen__pc" class="product--image product--4" src="../../assets/images/sub/product--4.png" alt="">
            <img id="screen__pc" class="product--image product--5" src="../../assets/images/sub/product--5.png" alt="">
            <img id="screen__pc" class="product--image product--6" src="../../assets/images/sub/product--6.png" alt="">
            <img id="screen__pc" class="product--image product--7" src="../../assets/images/sub/product--7.png" alt="">
        </div>
        <div v-else style="width: 100%; display: flex; flex-direction: column; align-items: center; gap: 4rem;">
            <img id="screen__pc" class="product--image product--4" src="../../assets/images/sub/product--4--en.png"
                alt="">
            <img id="screen__pc" class="product--image product--5" src="../../assets/images/sub/product--5--en.png"
                alt="">
            <img id="screen__pc" class="product--image product--6" src="../../assets/images/sub/product--6--en.png"
                alt="">
            <img id="screen__pc" class="product--image product--7" src="../../assets/images/sub/product--7--en.png"
                alt="">
        </div>
        <div v-if="isKoreanLanguage"
            style="width: 100%; display: flex; flex-direction: column; align-items: center; gap: 4rem;">
            <img id="screen__mobile" class="product--image product--4"
                src="../../assets/images/sub/product--4--mobile.png" alt="">
            <img id="screen__mobile" class="product--image product--5"
                src="../../assets/images/sub/product--5--mobile.png" alt="">
            <img id="screen__mobile" class="product--image product--6"
                src="../../assets/images/sub/product--6--mobile.png" alt="">
            <img id="screen__mobile" class="product--image product--7"
                src="../../assets/images/sub/product--7--mobile.png" alt="">
        </div>
        <div v-else style="width: 100%; display: flex; flex-direction: column; align-items: center; gap: 4rem;">
            <img id="screen__mobile" class="product--image product--4"
                src="../../assets/images/sub/product--4--mobile--en.png" alt="">
            <img id="screen__mobile" class="product--image product--5"
                src="../../assets/images/sub/product--5--mobile--en.png" alt="">
            <img id="screen__mobile" class="product--image product--6"
                src="../../assets/images/sub/product--6--mobile--en.png" alt="">
            <img id="screen__mobile" class="product--image product--7"
                src="../../assets/images/sub/product--7--mobile--en.png" alt="">
        </div>


        <button class="product--button">{{ $t('productSubTab.button') }}<span>></span></button>
    </div>
</template>

<script>
export default {
    name: 'ProductSubTab',
    computed: {
        isKoreanLanguage() {
            return this.$i18n.locale === 'ko';
        }
    }
}
</script>