<template>
  <div :class="['navbar', { 'navbar--white': isScrolledDown && !isDefaultRoute }]">
    <div class="navbar__logo">
      <a href="/">
        <img src="@/assets/images/main__logo.png" />
      </a>
    </div>
    <div class="navbar__links">
      <router-link v-for="(linkName, i) in navbarLinks" :key="i" :to="getLinkPath(linkName)" class="navbar__links--tag"
        id="screen__pc">
        {{ linkName }}
      </router-link>
      <div class="navbar__links--tag">
        <select v-model="selectedLanguage">
          <option value="ko">KOR</option>
          <option value="en">ENG</option>
        </select>
      </div>
      <a id="screen__mobile" class="navbar__links--tag" @click="toggleNavbarDetail()">
        <img src="@/assets/images/navbar__menu.svg">
      </a>
    </div>
  </div>
  <div v-if="showNavbarDetail" class="navbar__detail">
    <div class="navbar">
      <div class="navbar__logo">
        <router-link to="/" @click="toggleNavbarDetail()">
          <img src="@/assets/images/main__logo.png" />
        </router-link>
      </div>
      <div class="navbar__links">
        <a class="navbar__links--tag" @click="toggleNavbarDetail()">
          <img src="@/assets/images/navbar__menu--dark.svg">
        </a>
      </div>
    </div>
    <div class="navbar__detail--body">
      <router-link v-for="(linkName, i) in navbarLinks" :key="i" :to="getLinkPath(linkName)"
        class="navbar__detail--links" @click="toggleNavbarDetail">
        {{ linkName }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      navbarLinks: ['Company', 'Business', 'News', 'Contact Us'],
      showNavbarDetail: false,
      selectedLanguage: localStorage.getItem("localeLang") || 'en',
      isScrolledDown: false,
      isDefaultRoute: this.$route.path === '/',
    };
  },
  methods: {
    toggleNavbarDetail() {
      this.showNavbarDetail = !this.showNavbarDetail;
      if (this.showNavbarDetail) {
        window.scrollTo(0, 0);
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    },
    getLinkPath(linkName) {
      switch (linkName) {
        case 'Company':
          return '/company';
        case 'Business':
          return '/business';
        case 'News':
          return '/news';
        case 'Contact Us':
          return '/contact';
        default:
          return '/';
      }
    },
    changeLanguage() {
      this.$i18n.locale = this.selectedLanguage;
      localStorage.setItem('localeLang', this.selectedLanguage);
    },
    handleScroll() {
      this.isScrolledDown = window.scrollY > 0;
    }
  },
  watch: {
    selectedLanguage(newLang) {
      this.changeLanguage(newLang);
    },
    '$route.path'(newPath) {
      this.isDefaultRoute = newPath === '/';
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    document.body.style.overflow = 'auto';
  }
}
</script>
