import { createApp } from 'vue';
import App from './App.vue';
import VueFullPage from 'vue-fullpage.js';
import router from './router';
import i18n from "@/i18n/index.js";
import store from "@/store/index.js";
import VueGoogleMaps from '@fawmi/vue-google-maps';

const app = createApp(App);

app.use(VueFullPage);
app.use(router);
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyA6ksxRVywOxcTC_6Hgbmw_E-sLGcnUJWg',
    },
});
app.use(i18n);
app.use(store);
app.mount('#app');